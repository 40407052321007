// extracted by mini-css-extract-plugin
export var AddToOrderButton = "index-module--AddToOrderButton--11a2e";
export var AddToOrderButtonAndQuantitySelectorDesktop = "index-module--AddToOrderButtonAndQuantitySelectorDesktop--1a10f";
export var AddToOrderButtonAndQuantitySelectorMobile = "index-module--AddToOrderButtonAndQuantitySelectorMobile--014b4";
export var AllergensDecorator = "index-module--AllergensDecorator--c958a";
export var BigDesktopHeader = "index-module--BigDesktopHeader--66218";
export var CommentsOnly = "index-module--CommentsOnly--45302";
export var ComponentOptionPrice = "index-module--ComponentOptionPrice--e5a88";
export var HasLongDescription = "index-module--HasLongDescription--38a1e";
export var ItemComponentOption = "index-module--ItemComponentOption--76384";
export var MenuItemContainer = "index-module--MenuItemContainer--ebf0f";
export var MenuItemDescription = "index-module--MenuItemDescription--11eab";
export var MenuItemImage = "index-module--MenuItemImage--750b1";
export var MenuItemImageFocusStyle = "index-module--MenuItemImageFocusStyle--fcae1";
export var MenuSelector = "index-module--MenuSelector--948e1";
export var NoFields = "index-module--NoFields--d85df";
export var PlaceHolder = "index-module--PlaceHolder--3e649";
export var PlaceHolderIcons = "index-module--PlaceHolderIcons--15f38";
export var PreferencesIcon = "index-module--PreferencesIcon--3aa86";
export var QuantitySelector = "index-module--QuantitySelector--ee0d3";
export var TastePreferenceIcon = "index-module--TastePreferenceIcon--8c0f9";
export var WithExtraHeaderCompenstation = "index-module--WithExtraHeaderCompenstation--12fff";