import React from "react";
import _ from "lodash";
import Card from "../Card";
import TastePreferenceDecorator from "./TastePreferenceDecorator";

export default ({
  ingredients = [],
  hideMandatory = true,
  onChange = _.noop,
  appStyles,
  title = "Ingredients",
  color = "red",
  T,
  allergens,
}) => {
  const displayedIngredients = hideMandatory
    ? _.filter(ingredients, (ingredient) => !ingredient.isMandatory)
    : ingredients;

  if (displayedIngredients.length) {
    return (
      <Card appStyles={appStyles}>
        <Card.Header>
          <Card.Header.Title appStyles={appStyles}>
            {T(title)}
          </Card.Header.Title>
        </Card.Header>
        <Card.Content>
          <Card.Content.CheckboxGroup
            appStyles={appStyles}
            name="size"
            checked={_.map(
              _.filter(displayedIngredients, "isSelected"),
              ({ name }) => name.trim(),
            )}
            onChange={onChange}
            wrap
          >
            {_.map(displayedIngredients, (ingredient) => (
              <Card.Content.CheckboxOption
                iconSize={16}
                iconInnerSize={16}
                pointColor={color}
                value={ingredient.name.trim()}
                key={ingredient.name.trim()}
              >
                <TastePreferenceDecorator
                  title={T(ingredient.name)}
                  appStyles={appStyles}
                  allergens={allergens}
                />
              </Card.Content.CheckboxOption>
            ))}
          </Card.Content.CheckboxGroup>
        </Card.Content>
      </Card>
    );
  }
  return null;
};
